import React from 'react'
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from './PaymentForm';

const PUB_KEY = "pk_test_51K3QC9LlTBSQdqOFEUsWbDlkUM4O0jWUORA7PnEDOq3K8n91jzulQOrS77b81gogq4icfopymDL8JMlQcvpFVQGB00FmzUVZgO";

const stripeTestPromise = loadStripe(PUB_KEY); 

export default function StripeContainer() {
    return (
        <Elements stripe={stripeTestPromise}>
            <PaymentForm />
        </Elements>
    )
}