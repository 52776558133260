import React, { useState } from 'react';
import StripeContainer from './StripeContainer';
import './App.css'
import laptop from './laptop.jpg'

function App() {

  const [showItem, setShowItem] = useState(false)

  return (
    <div className="App">
      <>
        <h1 className="shopName">Shopping Place</h1>
        {showItem ? (
          <StripeContainer />
        ) : (
          <>
            <div className="infoTovar">
              <h3 className="tovarName">Microsoft Surface laptop</h3>
              <h2 className="tovarPrise">$2000</h2>
              <button className="purchaseBtn" onClick={() => setShowItem(true)}>Buy 🛒</button>
            </div>
            <img src={laptop} alt="laptop images" className="laptop" />
          </>
        )
        }
      </>
    </div>
  );
}

export default App;
